<template>
  <div
    class="w-full flex-none bg-white p-0 sm:p-2"
    :class="!isFull ? 'sm:w-full xl:w-full' : ''"
  >
    <div
      class="relative h-full w-full rounded-lg border-2 py-0"
      :class="group.isOpen ? '' : 'cursor-pointer hover:bg-gray-100'"
    >
      <div
        @click="group.isOpen = !group.isOpen"
        class="sticky top-0 z-20 flex cursor-pointer items-stretch space-x-2 rounded-lg bg-white text-center hover:bg-gray-100"
      >
        <div
          class="absolute -bottom-[2px] left-0 right-0 h-[2px] rounded-b-lg bg-[#e2e8f0]"
        ></div>
        <div
          class="flex flex-none origin-center items-center justify-center"
          :class="group.isOpen ? 'my-2 ml-2 h-12 w-12' : 'h-20 w-20'"
        >
          <image-getter
            classes="
                object-cover rounded-full w-full h-full
              "
            :url="getImageUrl(group.items[0].image.food, 'ar-1_1,w-100')"
            default="/images/product/awaitingimage.webp"
            :key="group.items[0].sku"
          />
        </div>
        <div
          class="flex flex-grow flex-col justify-center text-left"
          :class="!group.isOpen ? 'py-3' : ''"
        >
          <div :class="!group.isOpen ? '' : ''">
            <div
              class="default-tranition flex items-center space-x-2 text-lg font-semibold"
              :class="group.isOpen ? 'leaing-none' : ''"
            >
              <span class="">{{ group.name }}</span>
              <span class="default-trnsition relative">
                <span
                  class="flex items-center rounded bg-gray-200 px-1.5 py-1 text-[11px] font-medium uppercase leading-none tracking-wide text-gray-700"
                >
                  {{ group.type }}</span
                >
              </span>
            </div>
          </div>
          <div
            class="flex items-center space-x-2 text-sm text-gray-700"
            :class="!group.isOpen ? '' : 'text-sm'"
          >
            <div class="">
              {{ !group.isOpen ? 'View our' : 'Pick from our' }}
              <span
                class="border-b-2 border-pink-500 font-semibold text-black"
                >{{ group.items.length || group.items.length }}</span
              >
              Flavours!
            </div>
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center space-x-2 p-1 text-sm text-gray-700"
          v-if="totalInCategory > 0"
        >
          <div
            class="flex aspect-square w-12 flex-none flex-col items-center justify-center space-y-0.5 rounded-full border-2 border-yellow-300 bg-yellow-100 text-sm leading-none"
          >
            <div class="font-semibold">{{ totalInCategory }}</div>
            <font-awesome-icon class="text-base" :icon="['fal', 'box-open']" />
          </div>
        </div>
        <div class="flex items-center px-4 pl-1 text-2xl">
          <font-awesome-icon
            class="text-sm text-gray-700"
            :icon="['far', !group.isOpen ? 'chevron-down' : 'chevron-up']"
          />
        </div>
      </div>

      <div v-if="group.isOpen" class="-mr-[2px]">
        <div
          v-if="group.items && group.items.length > 0"
          class="grid-items-wrapper grid w-full flex-none grid-cols-2 text-sm md:grid-cols-3 lg:grid-cols-4"
          :class="isAdd ? '!grid-cols-2' : ''"
        >
          <div
            class="relative w-full bg-white"
            :class="index !== group.items.length ? 'grid-item' : ''"
            :key="`added-${index}`"
            v-for="(val, index) in group.items"
          >
            <product-item
              :product="val"
              :key="val.sku"
              :totals="totals"
              @updateProducts="updateProducts"
              :is-adjust="isAdjust"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductItem from '@/components/products/ProductItem.vue';
import ImageGetter from '@/components/ImageGetter.vue';
import utils from '@/utils';

export default {
  components: { ProductItem, ImageGetter },
  name: 'ProductCategory',
  props: {
    group: {
      type: Object,
      required: true
    },
    cat: {
      type: Object,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    isAdjust: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      subValidation: 'subValidation'
    }),
    totalInCategory() {
      if (this.group.key === 'dry') {
        return this.totals.dry / 800;
      }
      return this.totals[this.group.key] || {};
    },
    disableAdding() {
      if (this.group.label === 'dry') {
        return this.subValidation.isDryMax;
      }
      return this.subValidation.isWetMax;
    },
    isKitten() {
      return this.cat?.isKitten || this.cat?.type === 'kitten';
    }
  },
  data() {
    return {
      groupedProducts: [],
      added: []
    };
  },
  methods: {
    updateProducts: function (payload) {
      const groupItem = this.group.items.find((p) => p.sku === payload.sku);
      if (groupItem) {
        groupItem.quantity = payload.quantity;
      }

      let products = this.cat.products;
      const hasProduct = products.find((p) => p.sku === payload.sku);
      if (hasProduct) {
        hasProduct.quantity = payload.quantity;
      } else {
        products.push(payload);
      }
      this.cat.products = products.filter((p) => p.quantity !== 0);
    },
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  }
};
</script>
